<template>
    <body>

        <!-- preloader -->
        <Loader> </Loader>
        <!-- preloader -->
        <div class="frame">
            <div class="site-wrapper overflow-hidden">

                <!-- Header Section -->
                <Affiliate-Header> </Affiliate-Header>

                <section class="profilepage">
                    <div class="affiliate-programs">
                        <div class="container">
                            <div class="row pb-4">
                                <div class="col-12 col-md-12 col-lg-8">
                                    <div class="affi-header">

                                        <h1>Affiliate Program</h1>
                                        <p>Start the Affiliate Program With Our Services.</p>

                                        <div class="aff-list-psr">
                                            <ul>
                                                <li>
                                                    <span class="number-sp">1</span>
                                                    <span class="cntent-right">
                                                        Share products with your audience. We have customized linking tools
                                                        for large publishers, individual bloggers and social media
                                                        influencers.
                                                    </span>
                                                </li>
                                                <li>
                                                    <span class="number-sp">2</span>
                                                    <span class="cntent-right">
                                                        Earn up to 10% in affiliate fees from qualifying purchases and
                                                        programs. Our competitive conversion rates help maximize earnings.
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="number-of-count">
                                            <ul>
                                                <li>
                                                    <div class="number-of-digit">
                                                        <strong>{{ this.tot.toFixed(2) }}</strong>
                                                    </div>
                                                    <p>Total Earning</p>
                                                </li>
                                                <li>
                                                    <div class="number-of-digit">
                                                        <strong>{{ this.UpcomeingTot.toFixed(2) }}</strong>
                                                    </div>
                                                    <p>Upcoming Earnings</p>
                                                </li>
                                                <!-- <li>
                                                    <div class="number-of-digit">
                                                        <strong>$200</strong>
                                                    </div>
                                                    <p>Total Earn</p>
                                                </li> -->
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-12 col-md-12 col-lg-4">
                                    <div class="affilate-programs-img">
                                        <img src="../../assets/front/affiliate-program.png" alt="affiliate Programs">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div class="for-desktop">
                    <!-- <marquee>
                        <h4 style="color: #03e1bc!important;">You Can Create Link And Shere Link with Your Friends And
                            Earn Money</h4>
                    </marquee> -->
                    <Affiliate-Subscription id="subscription"></Affiliate-Subscription>
                </div>

                <div class="for_mobile_view">
                    <div class="top_slider_section">
                        <VueSlickCarousel class="slider-m-12d" :arrows="false" :dots="false">
                            <div v-for="subscription in subscriptionss" :key="subscription.id">
                                <div class="wrapper">
                                    <div class="thumb">
                                        <img :src="subscription.image" alt="default">
                                    </div>
                                    <div class="content">
                                        <h6 class="title">{{ subscription.title }}</h6>
                                        <p class="info">Buy it today</p>
                                        <router-link :to="{ path: '/subscriptions/' + subscription.id }" class="d-block"
                                            tabindex="0"><button>Buy now</button></router-link>
                                    </div>
                                </div>
                            </div>
                        </VueSlickCarousel>

                    </div>
                </div>


                <!-- Footer Section -->
                <Front-Footer> </Front-Footer>

            </div>
        </div>

    </body>
</template>

<script>
import VueMeta from 'vue-meta'
import Header from './Affiliate-Header';
import Footer from './Front-Footer';
import inspired from './common/Inspired';
import AffiliateSubscription from './common/Affiliate-Subscription';
import Loader from './Loader';
import Vue from 'vue'
import store from '@/store'
import moment from 'moment'

import { db } from '@/main'


import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
Vue.use(VueMeta)


export default {
    components: {
        'Affiliate-Header': Header,
        'Front-Footer': Footer,
        'Affiliate-Subscription': AffiliateSubscription,
        'Inspired': inspired,
        'Loader': Loader,
        'VueSlickCarousel': VueSlickCarousel


    },
    data() {
        return {
            components: {},
            pages: {},
            slickOptions: {
                //options can be used from the plugin documentation
                slidesToShow: 3,
                infinite: true,
                meta_title: '',
                meta_description: '',
            },
            subscriptionss: [],
            UpcomeingTot: '',
            tot: '',
        }
    },
    metaInfo() {
        //alert(this.meta_title);
        return {
            title: localStorage.getItem("meta_title"),
            meta: [
                { name: 'description', content: localStorage.getItem("meta_description") },
            ]
        }
    },
    created: function () {
        this.checkAffiliateUser();
        if (!localStorage.getItem('firstLoad')) {
            localStorage['firstLoad'] = true;
            window.location.reload();
        }
        else {
            localStorage.removeItem('firstLoad');
        }
        this.fetchComponents();
        this.fetchPages();
        this.fetchItems();
        this.paymentHistory();

    },

    methods: {

        checkAffiliateUser() {
            var id = localStorage.getItem("userSessionId");
            // alert(id)
            db.collection('users').doc(id).get()
                .then((doc) => {
                    if (!doc.data().affiliateStatus || doc.data().affiliateStatus == 0) {
                        this.$router.push({ name:'affiliate' })
                    } 
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
        },


        fetchItems() {
            store.dispatch('app/commitActivateLoader');
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.subscriptionss = [];
            this.slug = this.$route.params.slug;

            db.collection("countries")
                .where("slug", "==", this.slug)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((docs) => {

                        db.collection("subscriptions")
                            .where("country", "==", docs.id)
                            .get()
                            .then((querySnapshot) => {
                                querySnapshot.forEach((doc) => {

                                    // console.log(doc.id, " => ", doc.data());

                                    this.subscriptionss.push({
                                        id: doc.id,
                                        title: doc.data().title,
                                        image: doc.data().image,
                                    });

                                });
                            })
                            .catch((error) => {
                                console.log("Error getting documents: ", error);
                            });
                        store.dispatch('app/commitDeactivateLoader');

                    });
                });


            db.collection("components").doc('nlXcHYzVkqDaWGJgixjF')
                .get()
                .then((doc) => {
                    this.components = doc.data();
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });


        },

        fetchComponents() {

            db.collection("components").doc('ahotUyDbxXzk1h99c29N')
                .get()
                .then((doc) => {
                    // console.log('hhhh77');
                    // console.log(doc.id, " => ", doc.data());
                    this.components = doc.data();

                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });


        },
        async fetchPages() {

            await db.collection("pages").doc('91T5r0xlt47gme91ObsZ')
                .get()
                .then(async (doc) => {
                    // console.log('hhhh88');
                    // console.log(doc.id, " => ", doc.data());
                    this.pages = doc.data();
                    this.meta_title = doc.data().meta_title;
                    this.meta_description = doc.data().meta_description;
                    localStorage.setItem("meta_title", this.meta_title);
                    localStorage.setItem("meta_description", this.meta_description);


                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
            // alert(this.meta_title);

        },

        paymentHistory() {
            var uid = localStorage.getItem("userSessionId");
            store.dispatch('app/commitActivateLoader');

            this.rows = [];
            var i = 1;
            var j = 0
            this.tot = 0;
            this.UpcomeingTot = 0;
            db.collection("affiliates")
                .where("affiliate_id", "==", uid)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((docs) => {
                        db.collection("affiliates_discount")
                            .where("product", "==", docs.data().product_id)
                            .get()
                            .then((querySnapshot) => {
                                querySnapshot.forEach((doc) => {
                                    let Enddate = moment(docs.data().created).add(doc.data().cool_down_days, 'days');
                                    let currentdate = moment();
                                    this.max = doc.data().cool_down_days;
                                    if (doc.data().discount_type == "percent") {
                                        var discount_amount = doc.data().discount;
                                        // console.log("discount_amount", discount_amount)
                                        var Totalamount = (discount_amount / 100) * 99.5
                                        Totalamount = Totalamount.toFixed(2)
                                        // console.log("calculate_amount", Totalamount)
                                    } else {
                                        var Totalamount = doc.data().discount;
                                    }
                                    this.RemainingDays = datediff(currentdate._d, Enddate._d);
                                    // console.log("maximum-Day's", this.max)
                                    // console.log("Remaining-Day's", this.RemainingDays);
                                    // console.log("amount", docs.data().amount)
                                    this.value = this.max - (this.RemainingDays);

                                    this.rows.push({
                                        index: i,
                                        id: docs.id,
                                        // affiliate_id: docs.data().affiliate_id,
                                        user_id: docs.data().user_id,
                                        order_id: docs.data().order_id,
                                        product_id: docs.data().product_id,
                                        status: docs.data().delete_id,
                                        amount: Totalamount,
                                        cool_down_days: doc.data().cool_down_days,
                                        max: this.max,
                                        value: this.value,
                                        created: docs.data().created,
                                        modified: docs.data().modified,
                                    });
                                    // console.log(doc.id, " => ", doc.data());
                                    i++;
                                    store.dispatch('app/commitDeactivateLoader');
                                    // console.log(this.value);
                                    // console.log(j)
                                    if (this.rows[j].value >= this.rows[j].cool_down_days && this.rows[j].status != "1") {
                                        // console.log(this.rows[j])
                                        // console.log(j)
                                        this.rows[j].value = 100;
                                        this.rows[j].cool_down_days = 100;
                                        this.tot += parseFloat(this.rows[j].amount);
                                        // this.$refs['progressBar1'].style.display.none
                                        // console.log(this.$refs['progressBar1']);
                                        localStorage.setItem("total_earning", this.tot);
                                    } else if (this.rows[j].value <= this.rows[j].cool_down_days && this.rows[j].status != "1") {
                                        // console.log(this.rows[j])
                                        this.UpcomeingTot += parseFloat(this.rows[j].amount);
                                    } else {
                                        console.log("error!")
                                    }
                                    j++;
                                    // }
                                    // console.log(tot);
                                });
                                // console.log(this.UpcomeingTot);
                            })
                            .catch((error) => {
                                console.log("Error getting documents: ", error);
                                store.dispatch('app/commitDeactivateLoader');
                            });
                    });
                    store.dispatch('app/commitDeactivateLoader');
                })

            function datediff(first, second) {
                // Take the difference between the dates and divide by milliseconds per day.
                // Round to nearest whole number to deal with DST.
                return Math.round((second - first) / (1000 * 60 * 60 * 24));
            }
        },
    }
}

</script>
<style>
/* afflialate */

@import "../../assets/affiliate/css/style.css";

@import "../../../public/alam.css";

@import "../../assets/affiliate/instruction-css/animate.css";

/* @import "../../assets/affiliate/instruction-css/bootstrap.min.css"; */

@import "../../assets/affiliate/instruction-css/font-awesome.min.css";

@import "../../assets/affiliate/instruction-css/icofont.min.css";

@import "../../assets/affiliate/instruction-css/responsive.css";

.main-page {
    display: inline-block;
    white-space: nowrap;
    /* width: 409px;
    height: 65px; */
    font-family: Cinzel;
    font-weight: 700;
    font-size: 48px;
    line-height: 90px;
    font-style: normal;
    text-transform: uppercase;
}

@media only screen and (min-width: 360px) and (max-width: 479px) {
    h1.text-white.pt-3.mt-n5.me-2 {
        font-size: 2rem;
    }
}

.affiliate-programs {
    width: 100%;
    display: block;
    padding: 50px 0;
    margin: 0;
    padding-bottom: 0;
}

.affiliate-programs .affi-header h1 {
    font-weight: 700;
    font-size: 60px;
    line-height: 70px;
    color: #03E1BC;
    margin-bottom: 25px;
}

.affiliate-programs .affi-header p {
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
    color: #5F5F5F;
}

.affiliate-programs .affi-header .aff-list-psr ul {
    list-style: none;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.affiliate-programs .affi-header .aff-list-psr ul li {
    display: flex;
    justify-content: center;
    width: 100%;
}

.affiliate-programs .affi-header .aff-list-psr ul li span.cntent-right {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.2;
    color: #5F5F5F;
    width: 90%;
    display: inline-flex;
    align-items: center;
    padding-right: 20px;
}

.number-sp {
    width: 15%;
    font-size: 73px;
    display: inline-flex;
    justify-content: start;
    align-items: center;
    line-height: initial;
    font-weight: 600;
    color: #03E1BC;
    margin-top: -5px;
}

.affilate-programs-img {
    width: 100%;
    height: 100%;
    display: block;
    text-align: center;
}

.affilate-programs-img img {
    width: 100%;
    max-width: 380px;
}

.number-of-count ul {
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.number-of-count ul li {
    width: 100%;
    display: block;
}

.number-of-digit strong {
    color: #5F5F5F;
    font-weight: 600;
    font-size: 32px;
}

.number-of-count ul li p {
    font-size: 18px !important;
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 576px) {
    .affiliate-programs .row {
        flex-direction: column-reverse;
    }

    .affiliate-programs .affi-header h1 {
        font-size: 32px;
        margin-bottom: 10px;
    }

    .affiliate-programs .affi-header p {
        font-size: 18px;
    }

    .affiliate-programs .affi-header .aff-list-psr ul li {
        flex-direction: column;
    }

    .affiliate-programs .affi-header .aff-list-psr ul li span.cntent-right {
        width: 100%;
        padding: 0;
    }

    .number-of-count ul li p {
        font-size: 13px !important;
        padding: 0;
        margin: 0;
    }

    .number-of-digit strong {
        color: #5F5F5F;
        font-weight: 600;
        font-size: 28px;
    }

    section#subscription {
        margin-top: 75px;
    }
}
</style>
